import DataFormQuestion from '../../models/DataRequestHub/DataFormQuestion';
import { GridStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import {
    AnswerTypeVariable,
    AnswerTypeVariableNamesList,
    FormTypeVariable,
    FormTypeVariableNamesList,
    NumericFormatVariableNamesList,
} from '../../models/DataRequestHub/ProjectVariable';
import {
    DataTable,
    DataTableColumn,
    DataTableViewColumn,
} from '../../models/DataRequestHub/DataTable';
import { EditorTypeEnum } from '../../models/DataRequestHub/ProjectEditorEnum';

export const validateRequiredCell = (
    element: Element,
    classNameOfCell: string,
    valueToValidate: any
) => {
    const displayNameCellElement = element.querySelector(classNameOfCell);
    if (displayNameCellElement) {
        const stringValue = valueToValidate?.toString()?.trim();
        if (!stringValue) {
            addErrorClassToCellContent(displayNameCellElement);
            return true;
        } else {
            removeErrorClassFromCellContent(displayNameCellElement);
            return false;
        }
    }
};

export const isDateInvalid = (dateToValidate: any) => {
    const date = new Date(dateToValidate);
    return isNaN(date.getTime());
};

export const validateDateAndAdjustClasses = (
    element: Element,
    classNameOfCell: string,
    dateToValidate: any
) => {
    const displayNameCellElement = element.querySelector(classNameOfCell);

    const date = new Date(dateToValidate);
    const result = !isNaN(date.getTime());
    if (!result) {
        addErrorClassToCellContent(displayNameCellElement);
        return true;
    } else {
        removeErrorClassFromCellContent(displayNameCellElement);
        return false;
    }
};

const addErrorClassToCellContent = (cellElement: Element) => {
    setTimeout(() => {
        const cellContent = cellElement?.querySelector(
            '.MuiDataGrid-cellContent'
        );
        if (cellContent) {
            cellContent.classList.add('Mui-error');
        }
    }, 100);
};

export const removeErrorClassFromCellContent = (cellElement: Element) => {
    setTimeout(() => {
        const cellContent = cellElement?.querySelector(
            '.MuiDataGrid-cellContent'
        );
        if (cellContent) {
            cellContent.classList.remove('Mui-error');
        }
    }, 100);
};

export const validateRowDisplayOrder = <T extends DisplayOrderType>(
    rows: T[]
) => {
    const displayOrderMap = new Map<number, T[]>();

    for (const row of rows) {
        const displayOrder = row.displayOrder;
        if (!displayOrderMap.has(displayOrder)) {
            displayOrderMap.set(displayOrder, [row]);
        } else {
            displayOrderMap.get(displayOrder).push(row);
        }
    }

    const invalidOrderCells = Array.from(displayOrderMap.values()).filter(
        (rows) =>
            rows.length > 1 ||
            !rows[0]?.displayOrder ||
            rows[0]?.displayOrder < 1
    );

    return invalidOrderCells.length > 0 ? true : false;
};

export const validateRowUniqueName = <T extends DisplayNameType>(rows: T[]) => {
    const displayNameMap = new Map<string, T[]>();

    for (const row of rows) {
        const displayName = row.displayName.toLocaleLowerCase().trim();
        if (!displayNameMap.has(displayName)) {
            displayNameMap.set(displayName, [row]);
        } else {
            displayNameMap.get(displayName).push(row);
        }
    }

    const invalidNameCells = Array.from(displayNameMap.values()).filter(
        (rows) => rows.length > 1 || !rows[0]?.displayName
    );

    return invalidNameCells.length > 0 ? true : false;
};

export const validateSubQuestionsFormDisplayOrder = (
    subQuestions: DataFormQuestion[]
) => {
    const groupedSubQuestions = subQuestions.reduce(
        (mapResult, question): Map<string, DataFormQuestion[]> => {
            const key = question.parentQuestionId;
            if (!mapResult.has(key)) {
                mapResult.set(key, []);
            }
            mapResult.get(key).push(question);
            return mapResult;
        },
        new Map<string, DataFormQuestion[]>()
    );

    const hasError = Array.from(groupedSubQuestions).some(
        ([_, subQuestionsGroup]) =>
            hasSubQuestionsGroupDisplayOrderError(subQuestionsGroup)
    );

    return hasError;
};

const hasSubQuestionsGroupDisplayOrderError = (
    subQuestionsGroup: DataFormQuestion[]
) => {
    const displayOrderMap = new Map<number, [DataFormQuestion]>();
    for (const row of subQuestionsGroup) {
        const displayOrderSub = row.displayOrderSub;
        if (!displayOrderMap.has(displayOrderSub)) {
            displayOrderMap.set(displayOrderSub, [row]);
        } else {
            displayOrderMap.get(displayOrderSub).push(row);
        }
    }

    const invalidOrderCells = Array.from(displayOrderMap.values()).filter(
        (rows) =>
            rows.length > 1 ||
            !rows[0]?.displayOrderSub ||
            rows[0]?.displayOrderSub < 1
    );

    return invalidOrderCells.length > 0 ? true : false;
};

export const validateQuestionsFormDisplayTextSize = (
    rows: DataFormQuestion[]
) => {
    const invalidRows = rows.filter(
        (rows) =>
            rows.answerType === getAnswerTypeName(AnswerTypeVariable.Text) &&
            (!rows.displayTextSize || !rows.maxTextSize)
    ) as DataFormQuestion[];

    return invalidRows.length > 0 ? true : false;
};

export const validateRequiredFormType = <T extends FormTypeType>(rows: T[]) => {
    const invalidRows = rows.filter(
        (rows) =>
            rows.answerType !== getAnswerTypeName(AnswerTypeVariable.Text) &&
            rows.answerType !== getAnswerTypeName(AnswerTypeVariable.Date) &&
            !rows.formType
    );

    return invalidRows.length > 0 ? true : false;
};

export const validateRequiredFormList = <T extends FormListType>(rows: T[]) => {
    const invalidRows = rows.filter(
        (rows) =>
            rows.formType === getFormTypeName(FormTypeVariable.DropDown) &&
            !rows.formList
    );

    return invalidRows.length > 0 ? true : false;
};

const addDisableClassToCellContent = (cellElement: Element) => {
    setTimeout(() => {
        if (cellElement) {
            cellElement.classList.add('disabled');
        }
        removeInnerHtml(cellElement);
    }, 100);
};

const removeInnerHtml = (cellElement: Element) => {
    const cellContent = cellElement?.querySelector('.MuiDataGrid-cellContent');
    if (cellContent) {
        cellContent.innerHTML = '';
    }
};

const removeDisableClassFromCellContent = (cellElement: Element) => {
    setTimeout(() => {
        if (cellElement) {
            cellElement.classList.remove('disabled');
        }
    }, 100);
};
function getRowId(
    row: DataFormQuestion,
    editorType: EditorTypeEnum
): string | number;
function getRowId(
    row: DataTableColumn,
    editorType: EditorTypeEnum
): string | number;
function getRowId(
    row: DisplayOrderType,
    editorType: EditorTypeEnum
): string | number;
function getRowId(
    row: FormTypeType,
    editorType: EditorTypeEnum
): string | number;
function getRowId(
    row: FormListType,
    editorType: EditorTypeEnum
): string | number;
function getRowId(
    row: DisplayNameType,
    editorType: EditorTypeEnum
): string | number;

function getRowId(row: any, editorType: EditorTypeEnum): string | number {
    if (
        editorType === EditorTypeEnum.Questionnaire &&
        'customQuestionId' in row
    ) {
        return row.customQuestionId;
    }

    if (editorType === EditorTypeEnum.DataTable && 'customColumnId' in row) {
        return row.customColumnId;
    }

    if (
        editorType === EditorTypeEnum.DataTableView &&
        'customColumnId' in row
    ) {
        return row.customColumnId;
    }

    if ('gridId' in row && row.gridId !== undefined) {
        return row.gridId;
    }

    return row.id;
}

export const removeTextSizeIfAnswerTypeIsNotText = (
    templateRows: DataFormQuestion[]
) => {
    const rowsWithoutTextAnswerType = templateRows.filter(
        (rows) => rows.answerType !== 'Text'
    ) as DataFormQuestion[];

    const updatedTemplateRows = templateRows.map((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        if (
            rowsWithoutTextAnswerType.some(
                (y) => getRowId(y, EditorTypeEnum.Questionnaire) === id
            )
        ) {
            return { ...x, displayTextSize: '', maxTextSize: '' };
        }

        return x;
    }) as DataFormQuestion[];

    return updatedTemplateRows;
};

const getAnswerTypeName = (variable: AnswerTypeVariable) => {
    return AnswerTypeVariableNamesList.find((x) => x.id === variable).name;
};

const isNumericFormType = (formType: string) => {
    return NumericFormatVariableNamesList.some((x) => x.name === formType);
};

const isFormType = (formType: string) => {
    return FormTypeVariableNamesList.some((x) => x.name === formType);
};

const getFormTypeName = (variable: FormTypeVariable) => {
    return FormTypeVariableNamesList.find((x) => x.id === variable).name;
};

export const removeFormTypeIfNeeded = <T extends FormTypeType>(
    templateRows: T[]
) => {
    const rowsToRemoveFormType = templateRows.filter(
        (rows) =>
            rows.answerType === getAnswerTypeName(AnswerTypeVariable.Text) ||
            rows.answerType === getAnswerTypeName(AnswerTypeVariable.Date) ||
            (rows.answerType === getAnswerTypeName(AnswerTypeVariable.Form) &&
                !isFormType(rows.formType)) ||
            (rows.answerType === getAnswerTypeName(AnswerTypeVariable.Number) &&
                !isNumericFormType(rows.formType))
    );

    const updatedTemplateRows = templateRows.map((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        if (
            rowsToRemoveFormType.some(
                (y) => getRowId(y, EditorTypeEnum.Questionnaire) === id
            )
        ) {
            const element = document.querySelector('[data-id="' + id + '"]');
            const cellElement = element?.querySelector('.form-type');
            removeInnerHtml(cellElement);
            return { ...x, formType: '' };
        }

        return x;
    });

    return updatedTemplateRows;
};

export const removeFormListIfNeeded = <T extends FormListType>(
    templateRows: T[]
) => {
    const rowsToRemoveFormList = templateRows.filter(
        (rows) => rows.formType !== getFormTypeName(FormTypeVariable.DropDown)
    );

    const updatedTemplateRows = templateRows.map((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        if (
            rowsToRemoveFormList.some(
                (y) => getRowId(y, EditorTypeEnum.Questionnaire) === id
            )
        ) {
            const element = document.querySelector('[data-id="' + id + '"]');
            const cellElement = element?.querySelector('.form-list');
            removeInnerHtml(cellElement);
            return { ...x, formList: '' };
        }

        return x;
    });

    return updatedTemplateRows;
};

export const validateFormType = <T extends FormTypeType>(
    templateRows: T[],
    gridState: GridStateCommunity,
    editorType: EditorTypeEnum
) => {
    const rowsToDisableFormType = templateRows.filter(
        (rows) =>
            rows.answerType === getAnswerTypeName(AnswerTypeVariable.Text) ||
            rows.answerType === getAnswerTypeName(AnswerTypeVariable.Date) ||
            !rows.answerType
    );
    rowsToDisableFormType.forEach((x) => {
        const id = getRowId(x, editorType);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-type');

        addDisableClassToCellContent(cellElement);
    });

    const rowsToEnableFormType = templateRows.filter(
        (rows) =>
            rows.answerType !== getAnswerTypeName(AnswerTypeVariable.Text) &&
            rows.answerType !== getAnswerTypeName(AnswerTypeVariable.Date) &&
            rows.answerType
    );

    rowsToEnableFormType.forEach((x) => {
        const id = getRowId(x, editorType);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-type');
        removeDisableClassFromCellContent(cellElement);
    });

    const invalidRows = templateRows.filter(
        (rows) =>
            rows.answerType !== getAnswerTypeName(AnswerTypeVariable.Text) &&
            rows.answerType !== getAnswerTypeName(AnswerTypeVariable.Date) &&
            !rows.formType &&
            rows.answerType
    );

    invalidRows.forEach((x) => {
        const id = getRowId(x, editorType);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-type');
        addErrorClassToCellContent(cellElement);
    });

    const validRows = templateRows.filter((x) => {
        const result = !invalidRows.some((y) => {
            const invalidRowId = getRowId(y, editorType);
            const originalRowId = getRowId(x, editorType);
            return invalidRowId === originalRowId;
        });

        return result;
    });

    validRows.forEach((x) => {
        const id = getRowId(x, editorType);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-type');
        removeErrorClassFromCellContent(cellElement);
    });

    const editRows = gridState.editRows;
    const isEditing = Object.keys(editRows).length > 0;
    if (isEditing) {
        return false;
    }

    return invalidRows.length === 0;
};

export const validateFormList = <T extends FormListType>(
    templateRows: T[],
    gridState: GridStateCommunity,
    editorType: EditorTypeEnum
) => {
    const rowsToDisableFormList = templateRows.filter(
        (rows) => rows.formType !== getFormTypeName(FormTypeVariable.DropDown)
    );

    rowsToDisableFormList.forEach((x) => {
        const id = getRowId(x, editorType);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-list');

        addDisableClassToCellContent(cellElement);
    });

    const rowsToEnableFormList = templateRows.filter(
        (rows) => rows.formType === getFormTypeName(FormTypeVariable.DropDown)
    );

    rowsToEnableFormList.forEach((x) => {
        const id = getRowId(x, editorType);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-list');
        removeDisableClassFromCellContent(cellElement);
    });

    const invalidRows = templateRows.filter(
        (rows) =>
            rows.formType === getFormTypeName(FormTypeVariable.DropDown) &&
            !rows.formList
    );

    invalidRows.forEach((x) => {
        const id = getRowId(x, editorType);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-list');
        addErrorClassToCellContent(cellElement);
    });

    const validRows = templateRows.filter((x) => {
        const result = !invalidRows.some((y) => {
            const invalidRowId = getRowId(y, editorType);
            const originalRowId = getRowId(x, editorType);
            return invalidRowId === originalRowId;
        });

        return result;
    });

    validRows.forEach((x) => {
        const id = getRowId(x, editorType);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.form-list');
        removeErrorClassFromCellContent(cellElement);
    });

    const editRows = gridState.editRows;
    const isEditing = Object.keys(editRows).length > 0;
    if (isEditing) {
        return false;
    }

    return invalidRows.length === 0;
};

export const validateDisplayTextSize = (
    templateRows: DataFormQuestion[],
    gridState: GridStateCommunity
) => {
    const rowsToDisableTextSize = templateRows.filter(
        (rows) => rows.answerType !== getAnswerTypeName(AnswerTypeVariable.Text)
    ) as DataFormQuestion[];
    rowsToDisableTextSize.forEach((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-text-size');
        addDisableClassToCellContent(cellElement);
    });

    const rowsToEnableTextSize = templateRows.filter(
        (rows) => rows.answerType === getAnswerTypeName(AnswerTypeVariable.Text)
    ) as DataFormQuestion[];

    rowsToEnableTextSize.forEach((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-text-size');
        removeDisableClassFromCellContent(cellElement);
    });

    const invalidRows = templateRows.filter(
        (rows) =>
            rows.answerType === getAnswerTypeName(AnswerTypeVariable.Text) &&
            (!rows.displayTextSize || !rows.maxTextSize)
    ) as DataFormQuestion[];

    invalidRows.forEach((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-text-size');
        addErrorClassToCellContent(cellElement);
    });

    const validRows = templateRows.filter((x) => {
        const result = !invalidRows.some((y) => {
            const invalidRowId = getRowId(y, EditorTypeEnum.Questionnaire);
            const originalRowId = getRowId(x, EditorTypeEnum.Questionnaire);
            return invalidRowId === originalRowId;
        });

        return result;
    });

    validRows.forEach((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-text-size');
        removeErrorClassFromCellContent(cellElement);
    });

    const editRows = gridState.editRows;
    const isEditing = Object.keys(editRows).length > 0;
    if (isEditing) {
        return false;
    }

    return invalidRows.length === 0;
};

interface DisplayOrderType {
    id: number;
    displayOrder: number;
}

interface FormTypeType {
    id: number;
    answerType: string;
    formType: string;
}

interface FormListType {
    id: number;
    answerType: string;
    formType: string;
    formList: string;
}

interface DisplayNameType {
    id: number;
    displayName: string;
}

export const validateGridEditorRowDisplayOrder = <T extends DisplayOrderType>(
    templateRows: T[],
    gridState: GridStateCommunity,
    editorType: EditorTypeEnum,
    isSubQuestionCheck?: (row: T) => boolean
): boolean => {
    if (!gridState) {
        return false;
    }
    const displayOrderMap = new Map<number, T[]>();

    for (const row of templateRows.filter(
        (question) => !isSubQuestionCheck || !isSubQuestionCheck(question)
    )) {
        const displayOrder = row.displayOrder;
        if (!displayOrderMap.has(displayOrder)) {
            displayOrderMap.set(displayOrder, [row]);
        } else {
            displayOrderMap.get(displayOrder).push(row);
        }
    }

    const invalidOrderCells = Array.from(displayOrderMap.values()).filter(
        (rows) =>
            rows.length > 1 ||
            !rows[0]?.displayOrder ||
            rows[0]?.displayOrder < 1
    );

    const rowsWithInvalidOrder = invalidOrderCells.flat() as T[];

    rowsWithInvalidOrder.forEach((x) => {
        const id = getRowId(x, editorType);
        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-order');
        addErrorClassToCellContent(cellElement);
    });

    const rowsWithValidOrder = templateRows.filter((x) => {
        const result = !rowsWithInvalidOrder.some((y) => {
            const invalidRowId = getRowId(y, editorType);
            const originalRowId = getRowId(x, editorType);
            return invalidRowId === originalRowId;
        });

        return result;
    });

    rowsWithValidOrder.forEach((x) => {
        const id = getRowId(x, editorType);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-order');
        removeErrorClassFromCellContent(cellElement);
    });

    const editRows = gridState.editRows;
    const isEditing = Object.keys(editRows).length > 0;
    if (isEditing) {
        return false;
    }

    return rowsWithInvalidOrder.length === 0;
};

export const validateUniqueItemName = <T extends DisplayNameType>(
    templateRows: T[],
    gridState: GridStateCommunity,
    editorType: EditorTypeEnum
): boolean => {
    if (!gridState) {
        return false;
    }
    const displayOrderMap = new Map<string, T[]>();

    for (const row of templateRows) {
        const displayName = row.displayName?.toLocaleLowerCase().trim();
        if (!displayOrderMap.has(displayName)) {
            displayOrderMap.set(displayName, [row]);
        } else {
            displayOrderMap.get(displayName).push(row);
        }
    }

    const invalidOrderCells = Array.from(displayOrderMap.values()).filter(
        (rows) => rows.length > 1 || !rows[0]?.displayName
    );

    const rowsWithInvalidOrder = invalidOrderCells.flat() as T[];

    rowsWithInvalidOrder.forEach((x) => {
        const id = getRowId(x, editorType);
        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-name');
        addErrorClassToCellContent(cellElement);
    });

    const rowsWithValidOrder = templateRows.filter((x) => {
        const result = !rowsWithInvalidOrder.some((y) => {
            const invalidRowId = getRowId(y, editorType);
            const originalRowId = getRowId(x, editorType);
            return invalidRowId === originalRowId;
        });

        return result;
    });

    rowsWithValidOrder.forEach((x) => {
        const id = getRowId(x, editorType);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-name');
        removeErrorClassFromCellContent(cellElement);
    });

    const editRows = gridState.editRows;
    const isEditing = Object.keys(editRows).length > 0;
    if (isEditing) {
        return false;
    }

    return rowsWithInvalidOrder.length === 0;
};

export const validateSubQuestionDisplayOrder = (
    templateRows: DataFormQuestion[],
    gridState: GridStateCommunity
) => {
    if (!gridState) {
        return false;
    }

    let isValid = true;
    const subQuestions = templateRows.filter(
        (question) => question.isSubQuestion
    );

    const groupedSubQuestions = subQuestions.reduce(
        (mapResult, question): Map<string, DataFormQuestion[]> => {
            const key = question.parentQuestionId;
            if (!mapResult.has(key)) {
                mapResult.set(key, []);
            }
            mapResult.get(key).push(question);
            return mapResult;
        },
        new Map<string, DataFormQuestion[]>()
    );

    groupedSubQuestions.forEach((subQuestionsGroup) => {
        if (!validateSubQuestionGroup(subQuestionsGroup)) {
            isValid = false;
        }
    });

    const editRows = gridState.editRows;
    const isEditing = Object.keys(editRows).length > 0;
    if (isEditing) {
        return false;
    }

    return isValid;
};

const validateSubQuestionGroup = (
    subQuestions: DataFormQuestion[]
): boolean => {
    const displayOrderMap = new Map<number, [DataFormQuestion]>();
    for (const row of subQuestions) {
        const displayOrderSub = row.displayOrderSub;
        if (!displayOrderMap.has(displayOrderSub)) {
            displayOrderMap.set(displayOrderSub, [row]);
        } else {
            displayOrderMap.get(displayOrderSub).push(row);
        }
    }

    const invalidOrderCells = Array.from(displayOrderMap.values()).filter(
        (rows) =>
            rows.length > 1 ||
            !rows[0]?.displayOrderSub ||
            rows[0]?.displayOrderSub < 1
    );

    const rowsWithInvallidOrder =
        invalidOrderCells.flat() as DataFormQuestion[];

    rowsWithInvallidOrder.forEach((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-order-sub');
        addErrorClassToCellContent(cellElement);
    });

    const rowsWithValidOrder = subQuestions.filter((x) => {
        const result = !rowsWithInvallidOrder.some((y) => {
            const invalidRowId = getRowId(y, EditorTypeEnum.Questionnaire);
            const originalRowId = getRowId(x, EditorTypeEnum.Questionnaire);
            return invalidRowId === originalRowId;
        });

        return result;
    });

    rowsWithValidOrder.forEach((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-order-sub');
        removeErrorClassFromCellContent(cellElement);
    });

    return rowsWithInvallidOrder.length === 0;
};

export const validateDataTableViews = (
    dataTables: DataTable[],
    setInvalidTableViewIds?: (invalidViewIds: number[]) => void
): boolean => {
    const viewsValidaitionResult = dataTables.flatMap((table) =>
        table.views.map((view) => ({
            tableId: table.id,
            viewId: view.id,
            areRowsValid: validateDataTableViewsColumns(view.viewColumns),
        }))
    );

    if (setInvalidTableViewIds) {
        const invalidTableViewIds = viewsValidaitionResult
            .filter((result) => !result.areRowsValid)
            .map((result) => result.viewId);
        setInvalidTableViewIds(invalidTableViewIds);
    }

    const areTableViewsValid = !viewsValidaitionResult.some(
        (s) => !s.areRowsValid
    );

    return areTableViewsValid;
};

export const validateDataTableViewsColumns = (
    view: DataTableViewColumn[]
): boolean => {
    const includedColumns = view.filter((column) => column.isIncluded);
    const haveColumnsError = includedColumns.some(
        (column) => !column.overridenDisplayOrder
    );

    if (haveColumnsError) {
        return false;
    }

    const mappedColumns = includedColumns.map((column) => ({
        ...column,
        displayOrder: column.overridenDisplayOrder,
    }));
    if (validateRowDisplayOrder(mappedColumns)) {
        return false;
    }

    return true;
};

export const validateProjectDataTables = (
    dataTables: DataTable[],
    setInvalidTableIds?: (invalidViewIds: number[]) => void
): boolean => {
    const tablesValidationResult = dataTables.map((table) => ({
        tableId: table.id,
        areRowsValid: validateDataTableColumns(table.columns),
        isNameValid: !!table.originalTemplateName,
    }));

    if (setInvalidTableIds) {
        const invalidTableIds = tablesValidationResult
            .filter((result) => !result.areRowsValid || !result.isNameValid)
            .map((result) => result.tableId);
        setInvalidTableIds(invalidTableIds);
    }

    const areDataTablesValid = !tablesValidationResult.some(
        (s) => !s.areRowsValid || !s.isNameValid
    );

    return areDataTablesValid;
};

const validateDataTableColumns = (columns: DataTableColumn[]) => {
    const haveColumnsError = columns.some((column) =>
        doesColumnMissRequiredFields(column)
    );

    if (haveColumnsError) {
        return false;
    }

    if (validateRowDisplayOrder(columns)) {
        return false;
    }

    const mappedColumns = columns.map((m) => ({ ...m, displayName: m.name }));
    if (validateRowUniqueName(mappedColumns)) {
        return false;
    }

    if (validateRequiredFormType(mappedColumns)) {
        return false;
    }

    if (validateRequiredFormList(mappedColumns)) {
        return false;
    }

    return true;
};

const doesColumnMissRequiredFields = (tableColumn: DataTableColumn): boolean =>
    !tableColumn.displayOrder || !tableColumn.answerType || !tableColumn.name;
