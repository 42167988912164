import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
} from 'react';
import {
    DataTableColumn,
    DataTableViewColumn,
} from '../../../../../models/DataRequestHub/DataTable';
import {
    useGridApiRef,
    GridColDef,
    GridPreProcessEditCellProps,
    GridValidRowModel,
} from '@mui/x-data-grid';
import EditableTable from '../../../../../components/EditableTable/EditableTable';
import RowVisibilitySwitcherColumn from '../../../../../components/EditableTable/CustomColumns/RowVisibilitySwitcherColumn';
import { DataTableViewGridRow } from '../../../../../models/DataRequestHub/DataTableViewGridRow';
import {
    removeErrorClassFromCellContent,
    validateGridEditorRowDisplayOrder,
    validateRequiredCell,
} from '../../../../../components/EditableTable/EditableTableValidationHelper';
import { EditorTypeEnum } from '../../../../../models/DataRequestHub/ProjectEditorEnum';

interface DataTableViewEditorProps {
    tableViewGridRows: DataTableViewGridRow[];
    setTableViewGridRows(rows: DataTableViewColumn[]): void;
    isLoading: boolean;
}

const DataTableViewEditor = forwardRef(
    (props: DataTableViewEditorProps, ref) => {
        const apiRef = useGridApiRef();
        const { tableViewGridRows, setTableViewGridRows, isLoading } = props;

        const gridTableRef = useRef(null);
        useImperativeHandle(ref, () => ({
            addRow() {
                gridTableRef.current.addRow();
            },
            validateAllRows() {
                return validateAllRows();
            },
            getEditorState() {
                return apiRef.current.state;
            },
        }));

        const updateRows = (
            newRows: (
                oldColumns: DataTableViewGridRow[]
            ) => DataTableViewColumn[]
        ): void => {
            const result = newRows(tableViewGridRows).map((viewRow) => ({
                ...viewRow,
            }));
            setTableViewGridRows(result);
        };

        const getRowId = (row: GridValidRowModel) => {
            return row.customColumnId;
        };

        const isColumnIncludedSwitcherColumn = RowVisibilitySwitcherColumn({
            setRows: updateRows,
            gridApiRef: apiRef,
            getRowId: getRowId,
            columnHeader: 'Column Included',
            isDataTableView: true,
        });

        const columns = (): GridColDef[] => [
            isColumnIncludedSwitcherColumn,
            {
                field: 'displayOrder',
                headerName: 'View Order',
                minWidth: 140,
                flex: 0.25,
                type: 'number',
                cellClassName: (params) => {
                    let cellClass = 'cell-input display-order';

                    if (!params.row.isVisible) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                editable: true,
                align: 'left',
                headerAlign: 'left',
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const value = params.props.value;
                    const hasError = value <= 0;
                    return { ...params.props, error: hasError };
                },
            },
            {
                field: 'customColumnId',
                headerName: 'C-ID',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'cell-text-input custom-column-id disabled',
                editable: false,
            },
            {
                field: 'name',
                headerName: 'Column Name',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'cell-text-input column-name disabled',
                editable: false,
            },
            {
                field: 'answerType',
                headerName: 'Answer Type',
                minWidth: 170,
                cellClassName: 'answer-type disabled',
                flex: 0.5,
                editable: false,
            },
        ];

        const runValidation = (
            column: DataTableViewColumn,
            element: Element
        ): boolean => {
            let isValid = true;
            if (
                validateRequiredCell(
                    element,
                    '.overriden-display-order',
                    column.overridenDisplayOrder
                )
            ) {
                isValid = false;
            }

            return isValid;
        };

        const validateAllRows = () => {
            let isValid = true;
            for (let index = 0; index < tableViewGridRows.length; index++) {
                const rowId = tableViewGridRows[index].customColumnId;
                const element = document.querySelector(
                    '[data-id="' + rowId + '"]'
                );
                const column = tableViewGridRows[index];
                if (element && column.isVisible) {
                    const isColumnValid = runValidation(column, element);
                    if (!isColumnValid) {
                        isValid = false;
                    }
                } else {
                    removeErrorClassFromCellContent(element);
                }
            }

            apiRef.current.forceUpdate();
            const visibleRows = tableViewGridRows.filter((f) => f.isVisible);
            if (
                !validateGridEditorRowDisplayOrder(
                    visibleRows,
                    apiRef.current.state,
                    EditorTypeEnum.DataTableView
                )
            ) {
                isValid = false;
            }

            return isValid;
        };

        useEffect(() => {
            validateAllRows();
        }, [tableViewGridRows]);

        return (
            <EditableTable
                editorType={EditorTypeEnum.DataTableView}
                columns={columns()}
                rows={tableViewGridRows}
                setRows={updateRows}
                isLoading={isLoading}
                fieldToFocus="overridenDisplayOrder"
                ref={gridTableRef}
                gridApiRef={apiRef}
                validateAllRows={validateAllRows}
                disableActionColumn={true}
                disableVisibilityColumn={true}
            />
        );
    }
);

export default DataTableViewEditor;
