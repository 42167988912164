import React, { useEffect, useRef, useState } from 'react';
import './DataTableForm.scss';
import {
    DataTable,
    DataTableColumn,
    DataTableRow,
    DataTableProgressStatusList,
    DataTableViewProgressStatus,
    TableViewProgressStatusList,
} from '../../../models/DataRequestHub/DataTable';
import {
    GridColDef,
    GridPreProcessEditCellProps,
    GridValidRowModel,
    useGridApiRef,
} from '@mui/x-data-grid';
import EditableTable from '../../../components/EditableTable/EditableTable';
import { useStateSelector } from '../../../store/selectors';
import createNotification from '../../../utils/createNotification';
import { CustomIdConstants } from '../../../models/DataRequestHub/CustomIdConstants';
import DataTableFormActionColumn from './DataTableFormActionColumn';
import ButtonLoader from '../../../components/Layout/Buttons/ButtonLoader';
import {
    AnswerTypeVariable,
    AnswerTypeVariableNamesList,
    FormTypeVariable,
    FormTypeVariableNamesList,
    ProjectVariable,
} from '../../../models/DataRequestHub/ProjectVariable';
import { NumericColumn } from './NumericColumn/NumericColumn';
import moment from 'moment';
import { TimeZone, TimeZoneHelper } from '../../../utils/timeZoneHelper';
import { EditorTypeEnum } from '../../../models/DataRequestHub/ProjectEditorEnum';
import { Box } from '@mui/material';
import { Badge } from 'reactstrap';

interface DataTableFormProps {
    projectId: number;
    variables: ProjectVariable[];
    dataTable: DataTable;
    selectedTableViewId: number;
    isAdminView: boolean;
    updateProjectsData(isInitialFetch?: boolean): void;
}

export const DataTableForm = (props: DataTableFormProps) => {
    const apiRef = useGridApiRef();
    const [tableData, setTableData] = useState<DataTableRow[]>([]);
    const gridTableRef = useRef(null);
    const axios = useStateSelector((s) => s.core.axios);
    const [dataTable, setDataTable] = useState<DataTable>(null);
    const [isRequestPending, setIsRequestPending] = useState(false);
    const [isStatusUpdateInProcess, setIsStatusUpdateInProcess] =
        useState(false);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
    const [timeZone] = useState<TimeZone>(
        TimeZoneHelper.getTimeZoneByDate(new Date())
    );
    const cellsNotificationsStatus = useStateSelector(
        (s) => s.dataRequestsNotifications.tableCellsSeenStatus
    );
    const getCurrentTableView = () =>
        props.dataTable.views.find((x) => x.id === props.selectedTableViewId);

    const getCurrentTableViewColumns = () => {
        const selectedView = getCurrentTableView();

        const columnIds =
            selectedView?.viewColumns
                .sort(
                    (a, b) => a.overridenDisplayOrder - b.overridenDisplayOrder
                )
                .map((x) => x.customColumnId) ??
            props.dataTable.columns
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .map((x) => x.customColumnId);

        return columnIds
            .map((id) =>
                props.dataTable.columns.find(
                    (x) => x.customColumnId === id && x.isVisible
                )
            )
            .filter((x) => x != null);
    };

    const getVariableOptionsByName = (name: string) => {
        const variable = props.variables.find((x) => x.name === name);
        const result = variable.options.map((x) => x.option);
        return result;
    };

    const columns = (): GridColDef[] => {
        return getCurrentTableViewColumns().map((x) => {
            switch (x.answerType) {
                case AnswerTypeVariableNamesList.find(
                    (u) => u.id === AnswerTypeVariable.Form
                ).name:
                    return getDropDownColumn(x);
                case AnswerTypeVariableNamesList.find(
                    (u) => u.id === AnswerTypeVariable.Number
                ).name:
                    return getNumericColumn(x);

                case AnswerTypeVariableNamesList.find(
                    (u) => u.id === AnswerTypeVariable.Date
                ).name:
                    return getDateColumn(x);
                case AnswerTypeVariableNamesList.find(
                    (u) => u.id === AnswerTypeVariable.Text
                ).name:
                    return getTextInputColumn(x);

                default:
                    return getTextInputColumn(x);
            }
        });
    };

    const getDateColumn = (columnDefinition: DataTableColumn): GridColDef => {
        let result = {
            field: columnDefinition.customColumnId,
            headerName: columnDefinition.name,
            minWidth: 150,
            flex: 0.5,
            type: 'date',
            cellClassName: 'cell-date-input date-picker',
            editable: true,
            valueFormatter: (params: any) =>
                params?.value ? moment(params?.value).format('L') : null,
            valueSetter: (params) => {
                const { row, value } = params;
                const valueWithTImeZoneOffset = TimeZoneHelper.parseUtcDate(
                    new Date(value),
                    timeZone
                );

                const isDateValid = TimeZoneHelper.isDateValid(
                    valueWithTImeZoneOffset
                );

                return {
                    ...row,
                    [columnDefinition.customColumnId]: isDateValid
                        ? valueWithTImeZoneOffset
                        : '',
                };
            },
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError = !!!params.props.value;
                return { ...params.props, error: hasError };
            },
            renderCell: (params) => {
                return (
                    <Box className="MuiDataGrid-cellContent custom-render-cell">
                        <div>{params.formattedValue}</div>
                        {renderBadgeNotification(
                            columnDefinition.customColumnId,
                            params.row.customRowId
                        )}
                    </Box>
                );
            },
        } as GridColDef;

        return result;
    };

    const renderBadgeNotification = (
        columnCustomId: string,
        rowCustomId: string
    ) => {
        const cellNotificationStatus = cellsNotificationsStatus.find(
            (cell) =>
                props.dataTable.id === cell.tableId &&
                cell.customColumnId === columnCustomId &&
                cell.customRowId === rowCustomId
        );
        return !cellNotificationStatus.isSeenByUser && !props.isAdminView ? (
            <Badge className="cell-notification-badge unread-comments-badge">
                1
            </Badge>
        ) : (
            <></>
        );
    };

    const getNumericColumn = (
        columnDefinition: DataTableColumn
    ): GridColDef => {
        let result = {
            field: columnDefinition.customColumnId,
            headerName: columnDefinition.name,
            minWidth: 150,
            flex: 0.5,
            cellClassName: 'cell-text-input numeric-cell',
            editable: true,
            renderEditCell: (params: any) => (
                <NumericColumn
                    isReadonly={false}
                    formType={columnDefinition.formType}
                    {...params}
                />
            ),
            renderCell: (params: any) => (
                <Box className="MuiDataGrid-cellContent custom-render-cell">
                    <NumericColumn
                        isReadonly={true}
                        formType={columnDefinition.formType}
                        {...params}
                    />
                    {renderBadgeNotification(
                        columnDefinition.customColumnId,
                        params.row.customRowId
                    )}
                </Box>
            ),
        } as GridColDef;

        return result;
    };

    const getDropDownColumn = (
        columnDefinition: DataTableColumn
    ): GridColDef => {
        let valueOptions = [''];

        if (
            columnDefinition.formType ===
            FormTypeVariableNamesList.find(
                (u) => u.id === FormTypeVariable.DropDown
            ).name
        ) {
            valueOptions = getVariableOptionsByName(columnDefinition.formList);
        } else if (
            columnDefinition.formType ===
            FormTypeVariableNamesList.find(
                (u) => u.id === FormTypeVariable.YesNo
            ).name
        ) {
            valueOptions = ['Yes', 'No'];
        }
        let result = {
            field: columnDefinition.customColumnId,
            headerName: columnDefinition.name,
            minWidth: 150,
            flex: 0.5,
            type: 'singleSelect',
            valueOptions: valueOptions,
            cellClassName: 'cell-text-input',
            editable: true,
            renderCell: (params) => (
                <Box className="MuiDataGrid-cellContent custom-render-cell">
                    <div>{params.formattedValue}</div>
                    {renderBadgeNotification(
                        columnDefinition.customColumnId,
                        params.row.customRowId
                    )}
                </Box>
            ),
        } as GridColDef;

        return result;
    };

    const getTextInputColumn = (
        columnDefinition: DataTableColumn
    ): GridColDef => {
        const result = {
            field: columnDefinition.customColumnId,
            headerName: columnDefinition.name,
            minWidth: 150,
            flex: 0.5,
            type: 'string',
            cellClassName: 'cell-text-input',
            editable: true,
            renderCell: (params) => (
                <Box className="MuiDataGrid-cellContent custom-render-cell">
                    <div>{params.formattedValue}</div>
                    {renderBadgeNotification(
                        columnDefinition.customColumnId,
                        params.row.customRowId
                    )}
                </Box>
            ),
        } as GridColDef;

        return result;
    };

    const updateTableData = () => {
        let rowList = props.dataTable.rows.filter(
            (x) => (x.isVisible && !x.isRemovalRequested) || props.isAdminView
        );
        const columns = getCurrentTableViewColumns();

        const result = rowList.map((row) => {
            const newRow = { ...row } as any;

            row.cells.forEach((y) => {
                const column = columns.find(
                    (x) => x.customColumnId === y.customColumnId
                );
                if (column) {
                    newRow[column.customColumnId] = y.answerText;
                }
            });
            return newRow;
        });

        setTableData(result);
    };

    const clearRowsFromCellProperties = (
        updatedRows: DataTableRow[]
    ): DataTableRow[] => {
        return updatedRows.map((updatedRow) => {
            const newCells = updatedRow.cells.map((cell) => {
                const newAnswerText = updatedRow[
                    cell.customColumnId as keyof typeof updatedRow
                ] as string;
                const isNullOrUndefined =
                    newAnswerText === undefined || newAnswerText === null;
                return {
                    ...cell,
                    answerText: isNullOrUndefined
                        ? cell.answerText
                        : newAnswerText,
                };
            });

            return {
                id: updatedRow.id,
                customRowId: updatedRow.customRowId,
                isRemovalRequested: updatedRow.isRemovalRequested,
                isVisible: updatedRow.isVisible,
                cells: newCells,
            };
        });
    };

    const validateAllRows = () => {
        let isValid = true;

        return isValid;
    };

    const updateRows = (
        newRows: (oldRows: DataTableRow[]) => DataTableRow[]
    ): void => {
        const result = newRows(tableData);
        setTableData(result);
        debounceUpdateDataTableRows(result);
    };

    const filterUpdatedRows = (tableRows: DataTableRow[]) => {
        const updatedRows = tableRows.filter((tableRow) => {
            const originRow = dataTable.rows.find((f) => f.id === tableRow.id);

            if (originRow) {
                const hasAnyCellDifference = originRow.cells.some(
                    (originCell) => {
                        const tableCell = tableRow.cells.find(
                            (cell) => cell.id === originCell.id
                        );

                        if (tableCell) {
                            const tableCellAnswer = tableCell.answerText ?? '';
                            const originalTableCellAnswer =
                                originCell.answerText ?? '';
                            return (
                                tableCellAnswer !== originalTableCellAnswer ||
                                originRow.isVisible !== tableRow.isVisible
                            );
                        } else {
                            return false;
                        }
                    }
                );

                return hasAnyCellDifference;
            }

            return false;
        });

        return updatedRows;
    };

    const debounceUpdateDataTableRows = (tableRows: DataTableRow[]) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            const celanRows = clearRowsFromCellProperties(tableRows);
            const updatedRows = filterUpdatedRows(celanRows);

            if (!updatedRows.length) {
                return;
            }

            axios
                .put(
                    `/api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTable.id}/dataTableRows`,
                    updatedRows
                )
                .then((response) => {
                    if (response.status !== 200) {
                        const message = (response as any)?.response?.data
                            ?.detail;
                        createNotification(
                            message ?? 'An error occurred while updating row',
                            'error'
                        );
                    }

                    props.updateProjectsData();
                });
        }, 2000);
    };

    useEffect(() => {
        validateAllRows();
    }, [tableData]);

    useEffect(() => {
        if (
            JSON.stringify(dataTable?.rows) !==
                JSON.stringify(props.dataTable.rows) ||
            JSON.stringify(dataTable?.columns) !==
                JSON.stringify(props.dataTable.columns)
        ) {
            updateTableData();
            setDataTable(props.dataTable);
        }
    }, [props.dataTable]);

    const handleAddNewRow = () => {
        if (isRequestPending) return;

        setIsRequestPending(true);
        const ids = props.dataTable.rows.map((x) => x.customRowId);
        const newTableRowId =
            CustomIdConstants.getNextDataTableRowCustomId(ids);
        axios
            .post(
                `/api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTable.id}/dataTableRows`,
                { customDataTableRowId: newTableRowId }
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    const message = (response as any)?.response?.data?.detail;
                    createNotification(
                        message ?? 'An error occurred while adding row',
                        'error'
                    );
                }
            })
            .finally(() => {
                setIsRequestPending(false);
            });
    };

    const renderAddNewRow = () => (
        <ButtonLoader
            onClick={handleAddNewRow}
            buttonText={'+ Add New Row'}
            className={'btn btn-primary'}
            loaderButtonText={''}
            isLoading={isRequestPending}
            disabled={isRequestPending}></ButtonLoader>
    );

    const getRowId = (row: GridValidRowModel) => row.customRowId;

    const customActionColumn = DataTableFormActionColumn({
        setRows: updateRows,
        gridApiRef: apiRef,
        getRowId: getRowId,
        projectId: props.projectId,
        dataTableId: props.dataTable.id,
        updateProjectsData: props.updateProjectsData,
        isAdmin: props.isAdminView,
    });

    const handleTableViewStatusChange = (
        newStatus: DataTableViewProgressStatus
    ) => {
        const currentTableView = getCurrentTableView();
        setIsStatusUpdateInProcess(true);
        axios
            .put(
                `api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTable.id}/dataTableViews/${currentTableView.id}/progressStatus`,
                { progressStatus: newStatus }
            )
            .then((response) => {
                if (response.status !== 200) {
                    createNotification(
                        'An error occured while updating view status. Please, try again'
                    );
                } else {
                    props.updateProjectsData();
                }
            })
            .finally(() => {
                setIsStatusUpdateInProcess(false);
            });
    };

    const renderViewStauts = () => {
        const selectedTableView = getCurrentTableView();
        return props.isAdminView ? (
            <div className="table-view-progress-status-dropdown-wrapper">
                <div className="status">{'Status: '}</div>
                <select
                    disabled={isStatusUpdateInProcess}
                    value={selectedTableView.progressStatus}
                    style={{
                        color: TableViewProgressStatusList.find(
                            (x) =>
                                x.id == (selectedTableView.progressStatus ?? 0)
                        ).color,
                    }}
                    onChange={(event) => {
                        const value = Number(event.target.value);
                        handleTableViewStatusChange(value);
                    }}
                    name="table-view-progress-status"
                    className="table-view-progress-status-dropdown form-control">
                    {TableViewProgressStatusList.map((item, index) => (
                        <option
                            style={{ color: item.color }}
                            key={index + item.id + item.name}
                            value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
            </div>
        ) : (
            <div className="table-view-progress-status-dropdown-wrapper">
                <div className="status">{'Status: '}</div>
                <div
                    style={{
                        color: TableViewProgressStatusList.find(
                            (x) => x.id == selectedTableView.progressStatus
                        ).color,
                        fontFamily: 'SegoeUI-Bold',
                    }}>
                    {
                        TableViewProgressStatusList.find(
                            (x) => x.id == selectedTableView.progressStatus
                        ).name
                    }
                </div>
            </div>
        );
    };

    const renderTableStatus = () => (
        <div className="table-view-progress-status-dropdown-wrapper">
            <div className="status">{'Status: '}</div>
            <div
                style={{
                    color: DataTableProgressStatusList.find(
                        (x) => x.id == (props.dataTable.progressStatus ?? 0)
                    ).color,
                    fontFamily: 'SegoeUI-Bold',
                }}>
                {
                    DataTableProgressStatusList.find(
                        (x) => x.id == (props.dataTable.progressStatus ?? 0)
                    ).name
                }
            </div>
        </div>
    );

    return (
        <div className="data-table-view-host">
            <div className="status-tool-bar">
                {props.selectedTableViewId
                    ? renderViewStauts()
                    : renderTableStatus()}
            </div>
            <EditableTable
                editorType={EditorTypeEnum.DataTableData}
                columns={columns()}
                rows={tableData}
                setRows={updateRows}
                fieldToFocus="displayOrder"
                ref={gridTableRef}
                gridApiRef={apiRef}
                validateAllRows={validateAllRows}
                customActionColumn={customActionColumn}
                disableVisibilityColumn={!props.isAdminView}
            />
            {renderAddNewRow()}
        </div>
    );
};
