import { createSlice } from '@reduxjs/toolkit';
import { DataRequestNotificationsState } from './data-request-notifications.state';
import { isQuestionVisibleByLogic } from '../DataFormQuestion';
import { DataRequestProject } from '../DataRequestProject';
import { DataFormType } from '../DataFormTypeEnum';
import TabNotificationStatus from '../TabNotificationStatus';
import { QuestionProgressStatus } from '../QuestionProgressStatusEnum';
import QuestionSeenStatus, {
    calculateQuestionUserNotifications,
} from '../QuestionSeenStatus';
import Answer from '../Answer';
import FormNotificationStatus from '../FormNotificationStatus';
import { TableCellSeenStatus } from '../TableCellSeenStatus';
import {
    TableNotificationStatus,
    ViewNotificationStatus,
} from '../TableNotificationStatus';

const initialState: DataRequestNotificationsState = {
    isAdminView: undefined,
    questionsSeenStatus: [],
    formsNotificationsStatus: [],
    projectTabsNotificationsStatus: [],
    moduleTabsNotificationsStatus: [],
    tablesNotificationsStatus: [],
    tableCellsSeenStatus: [],
};

const defaultTabsStatus: TabNotificationStatus[] = [
    {
        formType: DataFormType.Documents,
        displayName: 'Documents',
        isNewProject: true,
        isNewForm: true,
        countOfNotifications: 0,
    },
    {
        formType: DataFormType.FinancialRequest,
        displayName: 'Financial Requests',
        isNewProject: true,
        isNewForm: true,
        countOfNotifications: 0,
    },
    {
        formType: DataFormType.Questionnaire,
        displayName: 'Questionnaire',
        isNewProject: true,
        isNewForm: true,
        countOfNotifications: 0,
    },
    {
        formType: DataFormType.DataTable,
        displayName: 'Questionnaire',
        isNewProject: true,
        isNewForm: true,
        countOfNotifications: 0,
    },
];

export interface ResetQuestionInfo {
    questionId: number;
    isSubquestion: boolean;
}

const dataRequestNotificationsSlice = createSlice({
    name: 'DataRequestNotifications',
    initialState,
    reducers: {
        initializeDataRequestNotifications(state, action) {
            const projects = action.payload.projects as DataRequestProject[];
            const isAdminView = action.payload.isAdminView as boolean;

            const quesiotnsStatus = mapQuestionsStatus(projects);
            const formsNotifications = isAdminView
                ? mapFormsAdminNotifications(quesiotnsStatus)
                : mapFormsUserNotifications(quesiotnsStatus);

            const cellsSeenStatus = mapCellsSeenStatus(projects);

            const tablesNotifications = mapTablesNotifications(
                cellsSeenStatus,
                projects,
                isAdminView
            );

            const updateFormTabsStatus = mapProjectTabsStatuses(
                formsNotifications,
                isAdminView
            );

            const updateModuleTabsStatus = isAdminView
                ? mapModuleTabsAdminStatuses(quesiotnsStatus)
                : mapModuleTabsUserStatuses(quesiotnsStatus);

            state.isAdminView = isAdminView;
            state.questionsSeenStatus = quesiotnsStatus;
            state.formsNotificationsStatus = formsNotifications;
            state.projectTabsNotificationsStatus = updateFormTabsStatus;
            state.moduleTabsNotificationsStatus = updateModuleTabsStatus;
            state.tableCellsSeenStatus = cellsSeenStatus;
            state.tablesNotificationsStatus = tablesNotifications;
        },
        updateQuestions(state, action) {
            const projects = action.payload.projects as DataRequestProject[];

            const updatedQuestionsStatus = updateQuestionsStatus(
                state.questionsSeenStatus,
                projects
            );

            updateTabsState(state, updatedQuestionsStatus);
            state.questionsSeenStatus = updatedQuestionsStatus;
        },
        resetQuestionsSeenStatusByFormType(state, action) {
            const targetFormType = action.payload.formType as DataFormType;

            const updatedQuestionsStatus = resetFormTypeQuestionsNotifications(
                state.questionsSeenStatus,
                targetFormType
            );
            updateTabsState(state, updatedQuestionsStatus);

            state.questionsSeenStatus = updatedQuestionsStatus;
        },
        resetQuestionsSeenStatusByFormId(state, action) {
            const targetFormId = action.payload.formId as number;

            const updatedQuestionsStatus = resetQuestionsNotificationsByFormId(
                state.questionsSeenStatus,
                targetFormId
            );
            updateTabsState(state, updatedQuestionsStatus);

            state.questionsSeenStatus = updatedQuestionsStatus;
        },
        resetQuestionsSeenStatusByIds(state, action) {
            const questionsInfoToReset = action.payload
                .resetQuestionInfo as ResetQuestionInfo[];
            const formId = action.payload.formId as number;

            const updatedQuestionsStatus = resetQuestionsNotifications(
                state.questionsSeenStatus,
                questionsInfoToReset,
                formId
            );
            updateTabsState(state, updatedQuestionsStatus);

            state.questionsSeenStatus = updatedQuestionsStatus;
        },
        addQuestionAnswer(state, action) {
            const answer = action.payload.answer as Answer;
            const resetQuestionInfo = action.payload
                .questionInfo as ResetQuestionInfo;

            let updatedQuestions = updateQuestionAnswer(
                state.questionsSeenStatus,
                answer,
                resetQuestionInfo
            );
            const changedQuestion = updatedQuestions.find(
                (question) =>
                    question.id === resetQuestionInfo.questionId &&
                    question.isSubQuestion === resetQuestionInfo.isSubquestion
            );
            updatedQuestions = mapNotificationsForAppearedQuestions(
                updatedQuestions,
                changedQuestion
            );

            state.questionsSeenStatus = updatedQuestions;
            updateTabsState(state, updatedQuestions);
        },
    },
});

const updateTabsState = (
    state: DataRequestNotificationsState,
    updatedQuestionsStatus: QuestionSeenStatus[]
) => {
    const formsNotifications = state.isAdminView
        ? mapFormsAdminNotifications(updatedQuestionsStatus)
        : mapFormsUserNotifications(updatedQuestionsStatus);
    const updateFormTabsStatus = mapProjectTabsStatuses(
        formsNotifications,
        state.isAdminView
    );
    const updateModuleTabsStatus = state.isAdminView
        ? mapModuleTabsAdminStatuses(updatedQuestionsStatus)
        : mapModuleTabsUserStatuses(updatedQuestionsStatus);

    state.formsNotificationsStatus = formsNotifications;
    state.projectTabsNotificationsStatus = updateFormTabsStatus;
    state.moduleTabsNotificationsStatus = updateModuleTabsStatus;
};

const mapQuestionsStatus = (
    projects: DataRequestProject[]
): QuestionSeenStatus[] => {
    const forms = projects.flatMap((project) => project.dataForms);

    if (forms.length) {
        const questionsSeenStatus = forms.flatMap((m) =>
            m.questions.map((q): QuestionSeenStatus => {
                const questionProject = projects.find((project) =>
                    project.dataForms.some((dataForm) =>
                        dataForm.questions.some(
                            (question) => question.id === q.id
                        )
                    )
                );
                const isNewProject = !questionProject.isSeenByUser;

                return {
                    ...q,
                    isSeenByUser:
                        isNewProject || m.isNew ? true : q.isSeenByUser,
                    isSeenByAdmin: q.isSeenByAdmin,
                    isUserQuestionHighlighted: q.isUserQuestionHighlighted,
                    isAdminFeedbackHighlighted:
                        isNewProject || m.isNew
                            ? false
                            : q.isAdminFeedbackHighlighted,
                    dataFormType: m.formType,
                    isMarkedAsComplete: q.isMarkedAsComplete,
                    isNewProject: isNewProject,
                    isNewForm: m.isNew,
                    formId: m.id,
                    projectId: questionProject.id,
                };
            })
        );

        return questionsSeenStatus;
    }

    return [];
};

const mapCellsSeenStatus = (
    projects: DataRequestProject[]
): TableCellSeenStatus[] => {
    const dataTables = projects.flatMap((project) => project.dataTables);
    if (dataTables) {
        const cellsSeenStatus = dataTables.flatMap((table) =>
            table.rows.flatMap((row): TableCellSeenStatus[] => {
                const sourceProject = projects.find((project) =>
                    project.dataTables.some(
                        (dataTable) => dataTable.id === table.id
                    )
                );

                const isNewProject = !sourceProject.isSeenByUser;

                const mappedCellsStatus = row.cells.map(
                    (cell): TableCellSeenStatus => ({
                        ...cell,
                        isSeenByUser: isNewProject ? true : cell.isSeenByUser,
                        isSeenByAdmin: cell.isSeenByAdmin,
                        projectId: sourceProject.id,
                        isNewProject: isNewProject,
                        tableId: table.id,
                        customRowId: row.customRowId,
                        isNewTable: false,
                    })
                );

                return mappedCellsStatus;
            })
        );

        return cellsSeenStatus;
    }

    return [];
};

const resetFormTypeQuestionsNotifications = (
    questionsStatus: QuestionSeenStatus[],
    formType: DataFormType
): QuestionSeenStatus[] => {
    if (formType === DataFormType.Questionnaire) {
        return questionsStatus;
    }

    const updatedQuestionsStatus = questionsStatus.map((questionStatus) => {
        if (questionStatus.dataFormType === formType) {
            const updatedQuestionStatus = {
                ...questionStatus,
                isSeenByUser: true,
                isSeenByAdmin: true,
                isUserQuestionHighlighted: false,
                isAdminFeedbackHighlighted: false,
                isNewForm: false,
                isNewProject: false,
            };

            return updatedQuestionStatus;
        }

        return questionStatus;
    });

    return updatedQuestionsStatus;
};

const resetQuestionsNotificationsByFormId = (
    questionsStatus: QuestionSeenStatus[],
    formId: number
): QuestionSeenStatus[] => {
    const updatedQuestionsStatus = questionsStatus.map((questionStatus) => {
        if (questionStatus.formId === formId) {
            const updatedQuestionStatus = {
                ...questionStatus,
                isSeenByUser: true,
                isSeenByAdmin: true,
                isUserQuestionHighlighted: false,
                isAdminFeedbackHighlighted: false,
                isNewForm: false,
                isNewProject: false,
            };

            return updatedQuestionStatus;
        }

        return questionStatus;
    });

    return updatedQuestionsStatus;
};

const resetQuestionsNotifications = (
    questionsStatus: QuestionSeenStatus[],
    questionIdsToReset: ResetQuestionInfo[],
    formId: number
): QuestionSeenStatus[] => {
    if (!questionIdsToReset.length || !questionsStatus.length) {
        return questionsStatus;
    }

    const isNewForm = questionsStatus
        .filter((f) => f.formId === formId)
        .every((e) => e.isNewForm);

    if (isNewForm) {
        return resetQuestionsNotificationsByFormId(questionsStatus, formId);
    }
    {
        const updatedQuestionsStatus = questionsStatus.map((status) => {
            const isTargetQuestion = !!questionIdsToReset?.find(
                (questionInfo) =>
                    questionInfo.questionId === status.id &&
                    status.isSubQuestion === questionInfo.isSubquestion
            );

            const isSubquestionOfTarget = !!questionIdsToReset?.find(
                (questionInfo) =>
                    questionInfo.questionId === status.originQuestionId
            );

            if (isTargetQuestion || isSubquestionOfTarget) {
                return {
                    ...status,
                    isSeenByUser: true,
                    isSeenByAdmin: true,
                    isUserQuestionHighlighted: false,
                    isAdminFeedbackHighlighted: false,
                    isNewForm: false,
                    isNewProject: false,
                };
            }

            return status;
        });

        return updatedQuestionsStatus;
    }
};

const mapProjectTabsStatuses = (
    formNotificationsStatus: FormNotificationStatus[],
    isAdminView: boolean
) => {
    const formTypeTabs = defaultTabsStatus;
    const result = formTypeTabs.map((tab): TabNotificationStatus => {
        const formTypeStatuse = formNotificationsStatus.filter(
            (status) => status.formType === tab.formType
        );

        const notificationsCount = formTypeStatuse
            .map((f) => f.notificationsCount)
            .reduce((sum, value) => sum + value, 0);

        const isFormNew = formTypeStatuse.every((e) => e.isNew);
        const isNewProject = formTypeStatuse.every((e) => e.isNewProject);

        return {
            ...tab,
            formType: tab.formType,
            isNewForm: isFormNew,
            countOfNotifications:
                isAdminView && isFormNew ? 0 : notificationsCount,
            isNewProject: isNewProject,
        };
    });

    return result;
};

const mapModuleTabsUserStatuses = (
    questionsSeenStatus: QuestionSeenStatus[]
): TabNotificationStatus[] => {
    const moduleTabs = mapModuleTabItems(questionsSeenStatus);

    const result = moduleTabs.map((moduleTab): TabNotificationStatus => {
        const formItems = questionsSeenStatus.filter(
            (question) => question.formId === moduleTab.formId
        );

        const questionsStatus = formItems.filter(
            (question) =>
                !question.isSubQuestion &&
                question.formId === moduleTab.formId &&
                question.displayModule === moduleTab.displayName
        );

        const subQquestionsStatus = formItems.filter(
            (question) =>
                question.isSubQuestion && question.formId === moduleTab.formId
        );

        const moduleNotificationsCount = questionsStatus
            .map((questionStatus) => {
                let count = calculateQuestionUserNotifications(
                    questionStatus,
                    formItems
                );

                if (
                    questionStatus.isSeenByUser &&
                    !questionStatus.isSubQuestion
                ) {
                    const subQuestionsNotifications = subQquestionsStatus
                        .filter(
                            (subQuestionStatus) =>
                                subQuestionStatus.originQuestionId ===
                                questionStatus.id
                        )
                        .reduce(
                            (sum, value) =>
                                sum +
                                calculateQuestionUserNotifications(
                                    value,
                                    formItems
                                ),
                            0
                        );

                    count += subQuestionsNotifications;
                }

                return count;
            })
            .reduce((sum, value) => sum + value, 0);

        return { ...moduleTab, countOfNotifications: moduleNotificationsCount };
    });

    return result;
};

const mapModuleTabsAdminStatuses = (
    questionsSeenStatus: QuestionSeenStatus[]
): TabNotificationStatus[] => {
    const moduleTabs = mapModuleTabItems(questionsSeenStatus);

    const result = moduleTabs.map((moduleTab): TabNotificationStatus => {
        const moduleQuestions = questionsSeenStatus.filter(
            (question) =>
                !question.isSubQuestion &&
                question.formId === moduleTab.formId &&
                question.displayModule === moduleTab.displayName
        );

        const moduleNotificationsmoduleNotificationsCount = moduleQuestions
            .map((questionStatus) => {
                let count = 0;

                if (questionStatus.isUserQuestionHighlighted) {
                    count += 1;
                }

                if (
                    !questionStatus.isSeenByAdmin &&
                    questionStatus.isMarkedAsComplete &&
                    questionStatus.status === QuestionProgressStatus.InReview
                ) {
                    count += 1;
                }
                return count;
            })
            .reduce((sum, value) => sum + value, 0);

        return {
            ...moduleTab,
            countOfNotifications: moduleNotificationsmoduleNotificationsCount,
        };
    });

    return result;
};

const mapModuleTabItems = (
    questionsSeenStatus: QuestionSeenStatus[]
): TabNotificationStatus[] => {
    const displayModules = questionsSeenStatus.map(
        (questionStatus): TabNotificationStatus | null =>
            questionStatus.displayModule
                ? {
                      formType: questionStatus.dataFormType,
                      displayName: questionStatus.displayModule,
                      isNewProject: true,
                      isNewForm: true,
                      countOfNotifications: 0,
                      formId: questionStatus.formId,
                  }
                : null
    );
    const notEmptyDisplayModules = displayModules.filter((x) => x);

    const uniqueDisplayModules = notEmptyDisplayModules.filter(
        (moduleState, index) => {
            const duplicateIndex = notEmptyDisplayModules.findIndex(
                (duplicateModuleState) =>
                    duplicateModuleState.displayName ===
                        moduleState.displayName &&
                    duplicateModuleState.formId === moduleState.formId &&
                    duplicateModuleState.formType === moduleState.formType
            );
            return index === duplicateIndex;
        }
    );

    return uniqueDisplayModules;
};

const updateQuestionAnswer = (
    questionsSeenStatus: QuestionSeenStatus[],
    answer: Answer,
    resetQuestionInfo: ResetQuestionInfo
): QuestionSeenStatus[] => {
    const targetQuestion = questionsSeenStatus.find(
        (f) =>
            f.id === resetQuestionInfo.questionId &&
            f.isSubQuestion === resetQuestionInfo.isSubquestion
    );
    let updatedQuestions = resetQuestionsNotifications(
        questionsSeenStatus,
        [resetQuestionInfo],
        targetQuestion.formId
    );

    updatedQuestions = updatedQuestions.map(
        (updatedQuestion): QuestionSeenStatus => {
            if (
                updatedQuestion.id === resetQuestionInfo.questionId &&
                updatedQuestion.isSubQuestion ===
                    resetQuestionInfo.isSubquestion
            ) {
                return {
                    ...updatedQuestion,
                    answer: answer,
                };
            }

            return updatedQuestion;
        }
    );

    return updatedQuestions;
};

const mapNotificationsForAppearedQuestions = (
    questionsSeenStatus: QuestionSeenStatus[],
    changedQuestion: QuestionSeenStatus
): QuestionSeenStatus[] => {
    const dependentQuestions = questionsSeenStatus.filter(
        (question) =>
            question.logicParentCustomId === changedQuestion.customQuestionId &&
            question.formId === changedQuestion.formId &&
            question.dataFormType === changedQuestion.dataFormType
    );

    const result = questionsSeenStatus.map((questionStatus) => {
        const currentDependentQuestion = dependentQuestions.find(
            (question) =>
                question.id === questionStatus.id &&
                question.dataFormType === questionStatus.dataFormType &&
                question.formId === questionStatus.formId &&
                question.isSubQuestion &&
                questionStatus.isSubQuestion
        );

        if (currentDependentQuestion) {
            const formQuestionsStatus = questionsSeenStatus.filter(
                (q) => q.formId === currentDependentQuestion.formId
            );
            const isVisibleByLogic = isQuestionVisibleByLogic(
                formQuestionsStatus,
                currentDependentQuestion
            );

            if (isVisibleByLogic && questionStatus.isSeenByUser) {
                return { ...questionStatus, isSeenByUser: false };
            }
        }

        return questionStatus;
    });
    return result;
};

const updateQuestionsStatus = (
    questionsStatus: QuestionSeenStatus[],
    projects: DataRequestProject[]
): QuestionSeenStatus[] => {
    const dataForms = projects.flatMap((project) => project.dataForms);

    if (dataForms.length) {
        const updatedQuestionsStatus = dataForms.flatMap(
            (form): QuestionSeenStatus[] =>
                form.questions.map((question): QuestionSeenStatus => {
                    const existenQuestionStatus = questionsStatus.find(
                        (questionStatus) =>
                            questionStatus.id === question.id &&
                            questionStatus.isSubQuestion ===
                                question.isSubQuestion
                    );

                    const questionProject = projects.find((project) =>
                        project.dataForms.some((dataForm) =>
                            dataForm.questions.some(
                                (formQuestion) =>
                                    formQuestion.id === question.id
                            )
                        )
                    );
                    const isNewProject = !questionProject.isSeenByUser;

                    if (existenQuestionStatus) {
                        return {
                            ...existenQuestionStatus,
                            isSeenByUser: existenQuestionStatus?.isSeenByUser,
                            isAdminFeedbackHighlighted:
                                existenQuestionStatus?.isAdminFeedbackHighlighted
                                    ? true
                                    : question.isAdminFeedbackHighlighted,
                            isSeenByAdmin: existenQuestionStatus?.isSeenByAdmin
                                ? question.isSeenByAdmin
                                : existenQuestionStatus?.isSeenByAdmin,
                            isUserQuestionHighlighted:
                                existenQuestionStatus?.isUserQuestionHighlighted
                                    ? true
                                    : question.isUserQuestionHighlighted,
                            dataFormType: form.formType,
                        };
                    }

                    return {
                        ...question,
                        isSeenByUser:
                            isNewProject || form.isNew
                                ? true
                                : question.isSeenByUser,
                        isSeenByAdmin: question.isSeenByAdmin,
                        isUserQuestionHighlighted:
                            question.isUserQuestionHighlighted,
                        isAdminFeedbackHighlighted:
                            isNewProject || form.isNew
                                ? false
                                : question.isAdminFeedbackHighlighted,
                        dataFormType: form.formType,
                        isMarkedAsComplete: question.isMarkedAsComplete,
                        isNewProject: isNewProject,
                        isNewForm: form.isNew,
                        formId: form.id,
                        projectId: questionProject.id,
                    };
                })
        );

        return updatedQuestionsStatus;
    }

    return questionsStatus;
};

const mapFormsAdminNotifications = (
    questionsSeenStatus: QuestionSeenStatus[]
): FormNotificationStatus[] => {
    const formsStatus = initializeFormsStatus(questionsSeenStatus);
    const result = formsStatus.map((formStatus): FormNotificationStatus => {
        const tabQuestionStatuses = questionsSeenStatus.filter(
            (status) => status.formId === formStatus.formId
        );
        const notificationsCount = tabQuestionStatuses
            .map((questionStatus) => {
                let count = 0;
                if (questionStatus.isUserQuestionHighlighted) {
                    count += 1;
                }
                if (
                    !questionStatus.isSeenByAdmin &&
                    questionStatus.isMarkedAsComplete &&
                    questionStatus.status === QuestionProgressStatus.InReview
                ) {
                    count += 1;
                }
                return count;
            })
            .reduce((sum, value) => sum + value, 0);
        return {
            ...formStatus,
            formType: formStatus.formType,
            notificationsCount: notificationsCount,
            isNewProject: false,
        };
    });

    return result;
};

const mapFormsUserNotifications = (
    questionsSeenStatus: QuestionSeenStatus[]
): FormNotificationStatus[] => {
    const formsStatus = initializeFormsStatus(questionsSeenStatus);
    const result = formsStatus.map((formStatus): FormNotificationStatus => {
        const tabQuestionStatuses = questionsSeenStatus.filter(
            (status) => status.formId === formStatus.formId
        );

        let notificationsCount = 0;

        const questionsStatus = tabQuestionStatuses.filter(
            (question) =>
                question.dataFormType === formStatus.formType &&
                !question.isSubQuestion
        );

        const subQuestionsStatus = tabQuestionStatuses.filter(
            (question) =>
                question.dataFormType === formStatus.formType &&
                question.isSubQuestion
        );

        const isNewProject = tabQuestionStatuses.every((q) => q.isNewProject);
        if (isNewProject) {
            return {
                ...formStatus,
                formType: formStatus.formType,
                notificationsCount: 0,
                isNewProject: isNewProject,
            };
        }

        if (formStatus.isNew) {
            notificationsCount = 1;
        } else {
            notificationsCount += questionsStatus
                .map((questionStatus) => {
                    let count = calculateQuestionUserNotifications(
                        questionStatus,
                        tabQuestionStatuses
                    );

                    if (
                        questionStatus.isSeenByUser &&
                        !questionStatus.isSubQuestion
                    ) {
                        const subQuestionsNotifications = subQuestionsStatus
                            .filter(
                                (subQuestionStatus) =>
                                    subQuestionStatus.originQuestionId ===
                                    questionStatus.id
                            )
                            .reduce(
                                (sum, value) =>
                                    sum +
                                    calculateQuestionUserNotifications(
                                        value,
                                        tabQuestionStatuses
                                    ),
                                0
                            );

                        count += subQuestionsNotifications;
                    }

                    return count;
                })
                .reduce((sum, value) => sum + value, 0);
        }

        return {
            ...formStatus,
            formType: formStatus.formType,
            notificationsCount: notificationsCount,
            isNewProject: isNewProject,
        };
    });
    return result;
};

const mapTablesNotifications = (
    cellsSeenStatus: TableCellSeenStatus[],
    projects: DataRequestProject[],
    isAdminView: boolean
) => {
    const tablesStatuses = initializeTablesStatus(projects);
    const result = tablesStatuses.map((tableStatus) => {
        if (tableStatus.isNewProject) {
            return tableStatus;
        }

        const viewNotifications = tableStatus.viewsNotificationsStatus.map(
            (viewStatus) => {
                const viewCells = cellsSeenStatus.filter((cell) =>
                    viewStatus.includedColumnCustomIds.some(
                        (includedColumnCustomId) =>
                            includedColumnCustomId === cell.customColumnId &&
                            cell.tableId === tableStatus.tableId
                    )
                );

                let viewNotificationCellsCount = 0;

                if (isAdminView) {
                    viewNotificationCellsCount =
                        viewCells.filter((viewCell) => !viewCell.isSeenByAdmin)
                            ?.length ?? 0;
                } else {
                    viewNotificationCellsCount =
                        viewCells.filter((viewCell) => !viewCell.isSeenByUser)
                            ?.length ?? 0;
                }

                return {
                    ...viewStatus,
                    notificationsCount: viewNotificationCellsCount,
                };
            }
        );

        const tableNotifications = viewNotifications.reduce(
            (a, b) => a + b.notificationsCount,
            0
        );

        return {
            ...tableStatus,
            viewsNotificationsStatus: viewNotifications,
            notificationsCount: tableNotifications,
        };
    });

    return result;
};

const initializeFormsStatus = (questionsSeenStatus: QuestionSeenStatus[]) => {
    return questionsSeenStatus
        .filter((question, index) => {
            const duplicateIndex = questionsSeenStatus.findIndex(
                (duplicateModuleState) =>
                    duplicateModuleState.formId === question.formId &&
                    duplicateModuleState.dataFormType === question.dataFormType
            );
            return index === duplicateIndex;
        })
        .map(
            (question): FormNotificationStatus => ({
                formId: question.formId,
                formType: question.dataFormType,
                notificationsCount: 0,
                isNew: question.isNewForm,
                isNewProject: question.isNewProject,
            })
        );
};

const initializeTablesStatus = (projects: DataRequestProject[]) => {
    const tablesNotificationsStatuses = projects.flatMap((project) =>
        project.dataTables.map(
            (table): TableNotificationStatus => ({
                tableId: table.id,
                notificationsCount: 0,
                isNew: false,
                isNewProject: project.isSeenByUser,
                viewsNotificationsStatus: table.views.map(
                    (view): ViewNotificationStatus => ({
                        viewId: view.id,
                        notificationsCount: 0,
                        includedColumnCustomIds: view.viewColumns.map(
                            (viewColumn) => viewColumn.customColumnId
                        ),
                    })
                ),
            })
        )
    );

    return tablesNotificationsStatuses;
};

export const {
    initializeDataRequestNotifications,
    updateQuestions,
    resetQuestionsSeenStatusByFormType,
    resetQuestionsSeenStatusByIds,
    addQuestionAnswer,
    resetQuestionsSeenStatusByFormId,
} = dataRequestNotificationsSlice.actions;
export default dataRequestNotificationsSlice.reducer;
